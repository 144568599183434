import {Col, Row} from "antd";
import React from "react";
import './FormHeader.css';

const FormHeader = ({
                   icon = null,
                   children,
                 }) => {
  return <Row>
    <Col className={"formHeader"} xs={24} sm={{"span": 16, "offset": 8}}>
      <h2>{icon} {children}</h2>
    </Col>
  </Row>
};

export default FormHeader