import React from 'react'
import { List } from 'antd';
import {EuroOutlined, SettingOutlined, UserOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

import {Content} from "antd/lib/layout/layout";
import {TugboatIcon} from "../icons/CustomIcons";

function SettingsMenu() {
  return (
    <Content>

      <List
      style={{width:"80%", marginLeft:"20px"}}
      header={<div><h2><SettingOutlined/> Einstellungen</h2></div>}
      bordered
          dataSource={[
            {title: "Tarife", link: "/_/settings/tarife", icon: <EuroOutlined />},
            {title: "Schlepper", link: "/_/settings/schlepper", icon: <TugboatIcon /> },
            {title: "Benutzer", link: "/_/settings/benutzer", icon: <UserOutlined /> },
          ]}
          renderItem={(item) => (
            <List.Item>
              <h2><Link to={item.link}>{item.icon} {item.title}</Link></h2>
            </List.Item>
          )}
        />
    </Content>

  )

}

export default SettingsMenu

