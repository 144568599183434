import React, {useEffect, useState} from 'react'
import {
  Button,
  Layout,
  Table,
  message,
  Spin,
  DatePicker,
  Space
} from 'antd';
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  EditOutlined,
  UserAddOutlined,
  WarningTwoTone
} from "@ant-design/icons";
import _ from "lodash";
import toernService from "../../services/ToernService";
import authenticationService from "../../services/AuthenticationService";
import dayjs from "dayjs";
import TableTitle from "../base/TableTitle";
import {ToernIcon} from "../icons/CustomIcons";
import MonthSelector from "../base/MonthSelector";

const { Content } = Layout;

const AlleToernsListe = () => {
  let {jahr, monat} = useParams();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const pMonat = jahr && monat ? dayjs(jahr + "-" + monat + "-01 00:00") : dayjs();

  const [datum, setDatum] = useState(pMonat);
  const [toernliste, setToernliste] = useState([]);
  const [loading, setloading] = useState(true);
  const [feldFilter, setFeldFilter] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    loadToerns()
  }, [datum]);

  const loadToerns = async () => {
    setloading(true);
    await toernService.getToernsFuerMonat(datum).then(
      res => {
        setloading(false);
        setToernliste(
          res.data.map(row => ({
            key: `t_${row.id}`,
            id: row.id,
            startzeit: row.startzeit,
            endezeit: row.endezeit,
            schlepper: row.schlepper,
            rang: row.rang,
            benutzerrang: row.benutzerrang,
            ruhezeitenfehler: row.ruhezeitenfehler,
            ruhezeitenfehleranzahl: row.ruhezeitenfehleranzahl,
            ruhezeiten: row.ruhezeiten,
            benutzer: row.benutzer,
            status: row.status
//            stundenlohn: row.stundenlohn,
//            abschlag: row.abschlag
          }))
        )
      }
    ).catch((e) => {
      setloading(false);
      error(`Beim Laden der Törns ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  };


  const handleChange = (pagination, filters, sorter) => {
    setFeldFilter(filters);
  };

  const changeMonat = (d) => {
    setDatum(d)
    navigate("/_/toerns/alle/"  + d.format("YYYY/MM") , { replace: true})
  }
  const tabletitle = () => {
    return <TableTitle icon={<ToernIcon/>}
                  filtervalues={
                    toernliste
                      .map(t => t.schlepper)
                      .filter((value, index, array) => array.indexOf(value) === index)
                      .map(s => ({ label: s, value: s}))
                  }
                  onFilter={setGlobalFilter} >Alle Törns</TableTitle>
  }

  const filterData = toernliste => formatter => toernliste.map( item => ({
    text: formatter(item),
    value: formatter(item)
  }));

  const columns=[
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (benutzer, record) => <>{toernService.toernStatus(record.status, false)}</>
    },
    {
      title: 'Benutzer',
      dataIndex: 'benutzer',
      key: 'benutzer',
      sorter: (a, b) => a.benutzer.nachname.localeCompare(b.benutzer.nachname),
      //filters: _.uniqWith(filterData(toernliste)(i => i.schlepper), _.isEqual),
      //filteredValue: feldFilter.schlepper || null,
      //onFilter: (value, record) => record.schlepper.includes(value),
      render: (benutzer) => <>{benutzer.nachname} {benutzer.vorname}</>
    },
    {
      title: 'Schlepper',
      dataIndex: 'schlepper',
      key: 'schlepper',
      sorter: (a, b) => a.schlepper.localeCompare(b.schlepper),
      filters: _.uniqWith(filterData(toernliste)(i => i.schlepper), _.isEqual),
      filteredValue: feldFilter.schlepper || null,
      onFilter: (value, record) => record.schlepper.includes(value),
    },
    // TODO: Status
    // TODO: Manuelle Logeinträge?
    {
      title: 'Von',
      dataIndex: 'startzeit',
      key: 'startzeit',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY HH:mm'): ""}</>,
      sorter: (a, b) => a.startzeit.localeCompare(b.startzeit),
    },
    {
      title: 'Bis',
      dataIndex: 'endezeit',
      key: 'endezeit',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY HH:mm'): ""}</>,
      sorter: (a, b) => a.endezeit.localeCompare(b.endezeit),
    },
    {
      title: 'Rang',
      dataIndex: 'rang',
      key: 'rang',
      render: (rang, record) => <>{rang=='Kapitaen' ? "Kapitän" : rang} {(rang !== record.benutzerrang) ? (<WarningTwoTone  title={"Benutzer: " + record.benutzerrang } />) : "" }</>,
    },
    /*
    {
      title: <HourglassTwoTone title={"Ruhezeiten"} />,
      dataIndex: 'ruhezeitenfehler',
      key: 'ruhezeitenfehler',
      render: (fehler, record) => <Space>
        <Badge size={"small"} count={record.ruhezeiten ? record.ruhezeiten.length : "0"}>
          <Link to={`/_/toern/${record.id}/ruhezeiten`}><Button icon={<HourglassOutlined/>} /></Link>
        </Badge>
        {(fehler) ?
          (<>
            <Badge size={"small"} count={record.ruhezeitenfehleranzahl}><Avatar icon={<WarningOutlined />} title={fehler} size="small"  /></Badge>
          </>)
          : "" }</Space>,
    },
    */
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      filteredValue: globalFilter ? [globalFilter] : null,
      onFilter: (value, record) => record.schlepper.toString().toLowerCase().includes(value.toLowerCase()),
      render: (id, record) => <Space>
        <Link to={"/_/toern/" + id}><Button icon={<EditOutlined />} /></Link>
        {/*
        <Link to={`/_/toern/${id}/ruhezeiten`}><Button icon={<HourglassOutlined/>} /></Link>
        */}
      </Space>,
    }
  ]

  return (
    <>
      {contextHolder}
      <Layout>
        <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex',  alignItems: 'center'}}>
          <Space>
            <MonthSelector onChange = { changeMonat } value={ datum } />
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <div style={{ padding: '16px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table title={tabletitle} columns={columns} dataSource={toernliste} onChange={handleChange}></Table>
          )}
        </Content>
      </Layout>
    </>
  )
}

export default AlleToernsListe