import React, {useEffect, useState} from 'react'
import {
  Button,
  Layout,
  message, Popconfirm, Space,
  Table
} from 'antd';
import {
  ArrowLeftOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom';
import schlepperService from "../../../services/SchlepperService";
import LadeIndikator from "../../LadeIndikator";
import authenticationService from "../../../services/AuthenticationService";
import TableTitle from "../../base/TableTitle";
import {Prompt} from "@five-show/antd-prompt";
import {TugboatAddIcon, TugboatIcon} from "../../icons/CustomIcons";

const Schlepperliste = () => {
  const [schlepperliste, setSchlepperliste] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState("");

/*
  const handleTableChange = (pagination, filters) => {
    setFeldFilter(filters);
  };
*/

  const handleError = (e, msg) => {
    if (e.response && e.response.data && e.response.data.status === 401) {
      message.error(`Ihre Nutzersitzung ist abgelaufen`);
      authenticationService.doLogout();
      navigate('/_/login');
    } else {
      message.error(msg ? msg :`Beim Laden der Daten ist ein Fehler aufgetreten`);
    }
  }

  const callApi = (apiCall, callback) => {
    apiCall().then(callback).catch((e) => {
      handleError(e);
    });
  }

  useEffect(() => {
    loadSchlepperliste();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSchlepperliste = async () => {
    setloading(true);
    await callApi(schlepperService.getSchlepper, (res => {
        setloading(false);
        setSchlepperliste(
          res.data.map(row => ({
            id: row.id,
            name: row.name,
          }))
        );
      }
    ))
  };

  const confirmSchlepperDeleted = async () => {
    message.success('Der Schlepper wurde gelöscht');
  }

  const deleteSchlepper = async (id) => {
    await schlepperService.deleteSchlepper(id).then(
      () => {
        loadSchlepperliste();
        confirmSchlepperDeleted();
      }
    ).catch((e) => {
      handleError(`Beim Löschen des Schleppers ist ein Fehler aufgetreten`);
    });
  }

  const legeSchlepperAn = async (schlepperName) => {
    var frm = {
      name: schlepperName,
    }
    await schlepperService.schlepperAnlegen(frm).then(
      () => {
        loadSchlepperliste();
      }
    ).catch((e) => {
      handleError(`Beim Anlegen des Schleppers ist ein Fehler aufgetreten`);
    });
  }
  const promptSchlepperAnlegen = async () => {
    try {
      await Prompt.prompt({
        label: "Bitte geben Sie den Namen des Schleppers ein:",
        required: true,
        onOk: (name) => legeSchlepperAn(name)
      });
    } catch (e) {
      console.log('prompt cancel', e);
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filteredValue: globalFilter ? [globalFilter] : null,
      onFilter: (value, record) =>
        record.name.toString().toLowerCase().includes(value.toLowerCase())
    },
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) =>
        <Popconfirm title="Schlepper löschen"
                    description={<>Soll der Schlepper<br/><i>{record.name}</i><br/>unwideruflich gelöscht werden?</>}
                    onConfirm={() => deleteSchlepper(id)} okText="Ja" cancelText="Nein">
          <Button icon={< DeleteOutlined/>} type="primary" danger title={"Schlepper löschen"}/>
        </Popconfirm>,
    }

  ];
  return (
    <>
      <Layout>

        <Layout.Header style={{backgroundColor: "#cccccc", display: 'flex', alignItems: 'center'}}>
          <Space>
            <Link to={"/_/settings"}><Button icon={<ArrowLeftOutlined/>}>Zur Einstellungsübersicht</Button></Link>
            <Button icon={< TugboatAddIcon/>} onClick={() => promptSchlepperAnlegen()}>Neuer Schlepper</Button>
          </Space>
        </Layout.Header>
        <Layout.Content>
          {loading ? (<LadeIndikator/>) : (
            <>
              <Table title={() => (<TableTitle
                icon={<TugboatIcon/>}
                filtervalues={ schlepperliste.map(s => ({ label: s.name, value: s.name})) }
                filter onFilter={setGlobalFilter} >Schlepper bearbeiten</TableTitle>)} columns={columns} dataSource={schlepperliste}></Table>
            </>
          )}
        </Layout.Content>
      </Layout>
    </>
  )
}

export default Schlepperliste

