import axios from 'axios'
import ServiceConfig from "../services/ServiceConfig";

const API_URL = ServiceConfig.API_URL;

class SchlepperService {

  getSchlepper() {
    return axios.get(`${API_URL}/api/v1/schlepper/`);
  }

  deleteSchlepper(schlepperId) {
    return axios.delete(`${API_URL}/api/v1/schlepper/` + schlepperId);
  }

  schlepperAnlegen(schlepperForm) {
    return axios.post(`${API_URL}/api/v1/schlepper`, schlepperForm);
  }

}
const schlepperService =  new SchlepperService();
export default schlepperService;