import React, {useEffect, useState} from 'react'
import {
  message,
} from 'antd';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import toernService from "../../services/ToernService";
import authenticationService from "../../services/AuthenticationService";
import dayjs from "dayjs";
import {CookiesProvider, useCookies} from "react-cookie";

const ToernDetailSeite = () => {
  const [cookies, setCookie] = useCookies(['tug'])

  let {toernId} = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(toernId !== 'neu');
  const [toern, setToern] = useState({});

  useEffect(() => {
    if (toernId !== 'neu') {
      ladeToern();
    } else {
      initToern();
    }
  }, []);

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  const handleError = (e, msg) => {
    if (e.response && e.response.data && e.response.data.status === 401) {
      message.error(`Ihre Nutzersitzung ist abgelaufen`);
      authenticationService.doLogout();
      navigate('/_/login');
    } else {
      message.error(msg ? msg :`Beim Laden der Daten ist ein Fehler aufgetreten`);
    }
  }

  const showInfo = (msg) => {
    messageApi.open({
      type: 'info', content: msg,
    });
  }

  const initToern = () => {
    setToern({
      "startzeit": dayjs().minute(Math.floor(dayjs().minute() / 30) * 30),
      "rang": authenticationService.getRang(),
      "schlepper": cookies.tug
    });
  }

  const callApi = (apiCall, callback) => {
    apiCall().then(callback).catch((e) => {
      handleError(e);
    });
  }

  const ladeToern = async () => {
    setLoading(true);
    await toernService.getToern(toernId).then(
      res => {
        setLoading(false);
        setToern(res.data);
      }
    ).catch((e) => {
      setToern({})
      if (e.response && e.response.data) {
        handleError(e, e.response.data.message);
      } else if (e.request) {
        handleError(e,"Der Server antwortet im Moment nicht. Bitte versuchen Sie es in Kürze erneut")
      } else {
        handleError(e, "Es ist ein unerwartetes Problem beim Holen der Daten aufgetreten!");
      }
    });
  };

  return (
    <>
      <CookiesProvider>
      <Outlet context={[toern, setToern, loading]} />
      </CookiesProvider>
    </>
  )
}
export default ToernDetailSeite

