import {Button, Popconfirm, Space, Table} from "antd";
import React from "react";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {EditOutlined} from "@ant-design/icons";
import {CalendarRemoveIcon} from "../../icons/CustomIcons";

const Tarifzeitraumtabelle = ({ zeitraeume, onDelete }) => {

  const tarifRowTableRender = (record) => {
    const columns = [
      {
        title: 'Rang',
        dataIndex: 'rang',
        key: 'rang',
        render: (rang) => <>{rang=='Kapitaen' ? "Kapitän" : rang}</>,
      },
      {
        title: 'SFN-Zuschlag',
        dataIndex: 'abschlag',
        key: 'abschlag',
        render: (a) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(a)
      },
      {
        title: 'Grundheuer',
        dataIndex: 'grundheuer',
        key: 'grundheuer',
        render: (s) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(s)
      },
      {
        title: 'Stundenlohn',
        dataIndex: 'stundenlohn',
        key: 'stundenlohn',
        render: (s) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(s)
      },
      {
        title: 'Aktion',
        dataIndex: 'id',
        key: 'operation',
        width: 200,
        render: () => <Space>
        </Space>,

      },
    ];
    return <Table columns={columns} dataSource={record.tarife} pagination={false} />;
  };

  const columns = [
    {
      title: 'Gültig ab',
      dataIndex: 'gueltigAb',
      key: 'gueltigAb',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY'): ""}</>,
    },
    {
      title: 'Aktion',
      dataIndex: 'id',
      width: 200,
      key: 'id',
      render: (zeitraumId, record) => <Space>
        <Link to={"/_/settings/tarife/tarifzeitraum/" + zeitraumId } ><Button icon={<EditOutlined />} title={"Tarifzeitraum bearbeiten"} /></Link>
        <Popconfirm title="Zeitraum löschen" description={<>Sollen die Tarifinformationen die ab <i>{record.gueltigAb}</i> gültig sind unwideruflich gelöscht werden?</>} onConfirm={() => onDelete(zeitraumId)} okText="Ja" cancelText="Nein">
          <Button danger icon={<CalendarRemoveIcon />} title={"Tarifzeitraum löschen"} />
        </Popconfirm>

      </Space>,
    },
  ]

  return (
    <>
      <Table columns={columns} dataSource={zeitraeume} expandable={{
        expandedRowRender: tarifRowTableRender,
      }} pagination={false}/>
    </>
  )
}
export default Tarifzeitraumtabelle
