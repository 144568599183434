import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import "./index.css";

import 'dayjs/locale/de';
import dayjs from "dayjs";
import deDE from 'antd/locale/de_DE';

import {ConfigProvider} from "antd";

dayjs.locale('de');

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <ConfigProvider locale={deDE}>
      <App/>
    </ConfigProvider>
  </StrictMode>
);
