import React, {useState} from "react";
import {Button, DatePicker} from "antd";
import {StepBackwardOutlined, StepForwardOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const MonthSelector = ({
            value = dayjs(),
            defaultValue = value,
            onChange = null,
      }) => {

  const [monat, setMonat] = useState(value ? value : defaultValue);

  const onMonatChange = (m) => {
    var mn =  m != null ? m : defaultValue
    passeMonatAn(mn);
  }
  const decMonat = () => {
    if (monat) {
      passeMonatAn(monat.add(-1, 'month'));
    }
  }

  const incMonat = () => {
    if (monat) {
      passeMonatAn(monat.add(1, 'month'));
    }
  }


  const passeMonatAn = (m) => {
    setMonat(m)
    if (onChange) {
      onChange(m)
    }
  }

  return <>
    <DatePicker defaultValue={dayjs()}
                value={monat}
                format={['MMM YYYY', 'MM.YYYY', 'M.YYYY', 'M.YY', 'MM.YY', 'DD.MM.YYYY', 'D.M.YYYY']}
                picker="month"
                onChange={onMonatChange} />
    <Button icon={<StepBackwardOutlined />} onClick={decMonat} />
    <Button icon={<StepForwardOutlined />} onClick={incMonat} />
  </>
};

export default MonthSelector