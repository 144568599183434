import React, {useEffect, useState} from 'react'
import {
  AutoComplete,
  Avatar,
  Button,
  DatePicker,
  Form, Input,
  InputNumber,
  Layout,
  List,
  message,
  Select,
  Space,
  TimePicker,
  Typography
} from 'antd';
import {Link, useNavigate, useOutletContext, useParams} from 'react-router-dom';
import toernService from "../../services/ToernService";
import schlepperService from "../../services/SchlepperService";
import tarifService from "../../services/TarifService";
import authenticationService from "../../services/AuthenticationService";
import dayjs, {locale} from "dayjs";
import {MinusCircleOutlined, PlusOutlined, RollbackOutlined, UserAddOutlined} from '@ant-design/icons';
import {Comment} from '@ant-design/compatible';
import LadeIndikator from "../LadeIndikator";

const {Content} = Layout;

dayjs.locale('de')

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const ToernRuhezeitenBearbeitenSeite = () => {
  const [toern, setToern] = useOutletContext();
  const [messageApi, contextHolder] = message.useMessage();
  let {toernId} = useParams();
  let {ruhezeitId} = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(ruhezeitId !== 'neu');
  const [ruhezeit, setRuhezeit] = useState({});
  const [toerntag, setToerntag] = useState({})

  // TODO: Toern holen -> Startzeit / Endezeit
/*
  useEffect(() => {
    if (ruhezeitId == 'neu') {
      initRuhezeit()
    }
  }, [toern]);
*/
  useEffect(() => {
    if (ruhezeitId !== 'neu') {
      loadRuhezeit(ruhezeitId);
    } else {
      initRuhezeit();
    }
  }, []);

  useEffect(() => {
    form.setFieldValue('id', ruhezeit.id)
    form.setFieldValue(['start','datum'], dayjs(ruhezeit.startzeit))
//    form.setFieldValue(['start','zeit'], dayjs(ruhezeit.startzeit).minute(Math.floor(dayjs().minute() / 30) * 30))
    form.setFieldValue(['start','zeit'], dayjs(ruhezeit.startzeit))
    form.setFieldValue('endezeit', ruhezeit.endezeit ? dayjs(ruhezeit.endezeit) : null)
    form.setFieldValue('toernId', toernId)
  }, [ruhezeit]);

  const initRuhezeit = () => {
    setRuhezeit({
      'startzeit': toern.startzeit,
      'toernId': toernId
    })
  }

  const loadRuhezeit = async () => {
    setLoading(true);
    await toernService.getRuhezeit(ruhezeitId).then(
      res => {
        setLoading(false);
        setRuhezeit({
            id: res.data.id,
            toernId: toernId,
            startzeit: res.data.startzeit,
            endezeit: res.data.endezeit,
        })
      }
    ).catch((e) => {
      setLoading(false);
      error(`Beim Laden der Ruhezeit ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  }

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  const showInfo = (msg) => {
    messageApi.open({
      type: 'info', content: msg,
    });
  }

  const changeStartStunde = (date, dateString) => {
    const tag = dayjs(date).startOf('day');
    const ersterTag = dayjs(toern.startzeit).startOf('day');
    const letzerTag = toern.endezeit ? dayjs(toern.endezeit).startOf('day') : dayjs().startOf('day');

    if (tag.isSame(toern.startzeit, 'day')) {
      console.log("erster Tag")
    } else if (toern.endezeit && tag.isSame(toern.endezeit, 'day')) {
      console.log("letzter Tag")
    } else {
      console.log(date, dateString);
    }
  };


  const saveRuhezeit = (frm) => {
    var ruhezeit = {
      id: frm.id,
      startzeit: form2day('start'),
      endezeit: form2day('ende'),
      toernId: toernId,
    }

    console.log("Speichern" , ruhezeit);

    var future = (ruhezeitId === 'neu')
        ? toernService.createRuhezeit(authenticationService.getLoggedInUserName(), toernId, ruhezeit)
        : toernService.saveRuhezeit(authenticationService.getLoggedInUserName(), ruhezeit);

    future
        .then(res => {
          showInfo("Die Ruhezeit wurde gespeichert");
          if (ruhezeitId === 'neu') {
            navigate(`/_/toern/${toernId}/ruhezeiten`, { replace: true });
          }
          setLoading(false);
        })
        .catch((errorx) => {
              // Error
              if (errorx.response) {
                var errs = errorx.response.data;
                for (var k of Object.keys(errs)) {
                  form.setFields([{name: k, errors: [errs[k]]}]);
                }
                error("Bitte prüfen Sie die Eingaben");
              } else {
                error("Es ist ein unbekannter Fehler aufgetreten. Bitte prüfen Sie die Eingaben und versuchen Sie es erneut");
              }
            }
        );
  };


  const setzeDauer = (d) => {
    console.log(d)
    var datum = form2day('start').add(d, 'hour');
    form.setFieldValue(['ende','datum'], datum)
    form.setFieldValue(['ende','zeit'], datum)
    form.submit()
  }

  const form2day = (name) => {
    var datum = dayjs(form.getFieldValue([name, 'datum']))
    if (datum == null) {
      return null;
    }
    const zeit = dayjs(form.getFieldValue([name, 'zeit']));
    datum = datum.set('hour', zeit.hour()).set('minute', zeit.minute())
    return datum
  }


  return (
    <>
      {contextHolder}
      <Layout>
        <Layout.Header style={{backgroundColor: "#cccccc", display: 'flex', alignItems: 'center'}}>
          <Space>
            <Button onClick={() => navigate(-1)} icon={<RollbackOutlined/>}>Zurück</Button>
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <LadeIndikator/>
          ) : (
            <Form
              {...layout}
              form={form}
              style={{
                maxWidth: 600,
              }}
              onFinish={saveRuhezeit}
            >
              <h2>Törn vom {dayjs(toern.startzeit).format("DD.MM.YYYY HH:mm")}</h2>

              <h2>Ruhezeit {ruhezeitId === "neu" ? "erfassen" : "bearbeiten"}</h2>

              <Form.Item name={'id'} hidden={true} noStyle><Input type="hidden" /></Form.Item>

              <Form.Item label="Start">
                <Space.Compact style={{width:'100%'}}>
                  <Form.Item
                      name={['start', 'datum']}
                      noStyle
                      rules={[{required: true, message: 'Das Startdatum muss angegeben werden'}]}
                  >
                    <DatePicker
                        minDate={dayjs(toern.startzeit).startOf('day')}
                        maxDate={toern.endezeit ? dayjs(toern.startzeit).endOf('day') : dayjs().endOf('day')}
                        style={{width:'60%'}}
                        onChange={changeStartStunde}
                        format={"DD.MM.YYYY"}
                    />
                  </Form.Item>
                  <Form.Item
                      name={['start', 'zeit']}
                      noStyle
                      rules={[{required: true, message: 'Die Startzeit muss angegeben werden'}]}
                      format={"HH:mm"}
                  >
                    <TimePicker
                        style={{width:'40%'}}
                        minuteStep={30}
                        placeholder="Uhrzeit"
                        needConfirm={false}
                        format="HH:mm"
                    />

                    {/*
                         initialValue={dayjs().minute(Math.floor(dayjs().minute() / 30) * 30)}
                    */}

                  </Form.Item>
                </Space.Compact>
              </Form.Item>

              <Form.Item label="Start">
                <Space style={{width: '100%'}}>
                  <Button onClick={() => setzeDauer(1)}>+1h</Button>
                  <Button onClick={() => setzeDauer(2)}>+2h</Button>
                  <Button onClick={() => setzeDauer(4)}>+4h</Button>
                  <Button onClick={() => setzeDauer(6)}>+6h</Button>
                </Space>
              </Form.Item>

              <Form.Item label="Ende">
                <Space.Compact style={{width:'100%'}}>
                  <Form.Item
                      name={['ende', 'datum']}
                      noStyle
                      rules={[{required: true, message: 'Das Startdatum muss angegeben werden'}]}
                  >
                    <DatePicker
                        minDate={dayjs(toern.startzeit).startOf('day')}
                        maxDate={toern.endezeit ? dayjs(toern.startzeit).endOf('day') : null}
                        style={{width:'60%'}}
                        onChange={changeStartStunde}
                        format={"DD.MM.YYYY"}
                    />
                  </Form.Item>
                  <Form.Item
                      name={['ende', 'zeit']}
                      noStyle
                      rules={[{required: true, message: 'Die Startzeit muss angegeben werden'}]}
                      format={"HH:mm"}
                  >
                    <TimePicker
                        style={{width:'40%'}}
                        minuteStep={30}
                        placeholder="Uhrzeit"
                        needConfirm={false}
                        format="HH:mm"
                    />

                    {/*
                         initialValue={dayjs().minute(Math.floor(dayjs().minute() / 30) * 30)}
                    */}

                  </Form.Item>
                </Space.Compact>
              </Form.Item>
              {/*
              <Form.Item label="Ende" name={'endezeit'}>
                <DatePicker style={{width: '100%'}}  format={"DD.MM.YYYY HH:mm"} minuteStep={30}
                            showTime={{
                              defaultValue: [dayjs().minute(Math.floor(dayjs().minute() / 30) * 30), dayjs().minute(Math.ceil(dayjs().minute() / 30) * 30)],
                              needConfirm: false,
                            }}
                            onChange={() => console.log("Change Endezeit")}
                            onOk={() => console.log("OK Endezeit")} />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Space>
                  <Button onClick={() => navigate(-1)} icon={<RollbackOutlined/>}>Zurück</Button>

                  <Button htmlType="submit" >
                    Speichern
                  </Button>
                </Space>
              </Form.Item>
              */}
            </Form>


          )}


        </Content>
      </Layout>
    </>
  )
}
export default ToernRuhezeitenBearbeitenSeite

