import React, {useState} from 'react'
import { Outlet } from 'react-router-dom';
import dayjs from "dayjs";

const BordpersonalSeite = () => {
  const [jahr, setJahr] = useState(dayjs().year());

  return (<>
            <Outlet context={[jahr, setJahr]} />
        </>
    )
}

export default BordpersonalSeite
