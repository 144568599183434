import React, {useEffect, useState} from 'react'
import {
  Button, Col,
  Layout,
  message, Popconfirm, Row,
  Space, Table
} from 'antd';
import {Link, useNavigate, useOutletContext, useParams} from 'react-router-dom';
import toernService from "../../services/ToernService";
import authenticationService from "../../services/AuthenticationService";
import dayjs, {locale} from "dayjs";
import {
  DeleteOutlined,
  EditOutlined,
  FilterFilled, HourglassOutlined, HourglassTwoTone,
  RollbackOutlined,
  WarningTwoTone
} from '@ant-design/icons';
import {Comment} from '@ant-design/compatible';
import LadeIndikator from "../LadeIndikator";

const {Content} = Layout;

dayjs.locale('de')

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const ToernRuhezeitenListe = () => {
  const [messageApi, contextHolder] = message.useMessage();
  let {toernId} = useParams();
  const navigate = useNavigate();
  const [toern, setToern] = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [ruhezeitenliste, setRuhezeitenliste] = useState([]);

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    getRuhezeitenFuerToern();
  }, [toernId]);

  const getRuhezeitenFuerToern = async () => {
    setLoading(true);
    await toernService.getRuhezeitenFuerToern(toernId).then(
      res => {
        setLoading(false);
        setRuhezeitenliste(
          res.data.map(row => ({
            key: `t_${row.id}`,
            id: row.id,
            startzeit: row.startzeit,
            endezeit: row.endezeit,
          }))
        )
      }
    ).catch((e) => {
      setLoading(false);
      error(`Beim Laden der Törns ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  }

  const confirmRuhezeitDeleted = async () => {
    messageApi.success('Die Ruhezeit wurde gelöscht');
  }
  const deleteRuhezeit = async (id) => {
    // TODO: Rechte prüfen!
    await toernService.deleteRuhezeit(authenticationService.getLoggedInUserName(), id).then(
        () => {
          getRuhezeitenFuerToern();
          confirmRuhezeitDeleted();
        }
    ).catch((e) => {
      error(`Beim Löschen des Toerns ist ein Fehler aufgetreten`);
      console.error(e);
    });
  }
  const tabletitle = () => {
    return <Row gutter={[0,0]}>
      <Col flex="300px"><h2 style={{margin:'0px'}}>Ruhezeiten verwalten</h2></Col>
      <Col flex="auto" style={{textAlign:'right'}}>

        {/*
        <AutoComplete
          style={{marginLeft: 'auto', width:'300px', marginRight:'32px'}}
          options={ruhezeiten.map(b => ({ label: b.vorname, value: b.vorname}))}
          onChange = {q => setGlobalFilter( q !== "" ? q : null)}
          value={globalFilter}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        >
          <Input placeholder="Filtern" addonBefore={<FilterFilled style={(globalFilter)? {color: '#1677ff'}:{color: 'rgba(0, 0, 0, 0.29)'}} />} allowClear={true}/>
        </AutoComplete>
        */}

      </Col>
    </Row>
  }

  const columns=[
    {
      title: 'Von',
      dataIndex: 'startzeit',
      key: 'startzeit',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY HH:mm'): ""}</>,
      sorter: (a, b) => a.startzeit.localeCompare(b.startzeit),
    },
    {
      title: 'Bis',
      dataIndex: 'endezeit',
      key: 'endezeit',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY HH:mm'): ""}</>,
      sorter: (a, b) => a.endezeit.localeCompare(b.endezeit),
    },
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => <Space>
        <Link to={`/_/toern/${toernId}/ruhezeit/${id}`}><Button icon={<EditOutlined/>} /></Link>
        <Popconfirm title="Ruhezeit löschen" description={<>Soll die Ruhezeit beginnend am<br/><i>{dayjs(record.startzeit).format('DD.MM.YYYY HH:mm')}</i><br/>unwideruflich gelöscht werden?</>} onConfirm={() => deleteRuhezeit(id)} okText="Ja" cancelText="Nein">
          <Button icon={< DeleteOutlined />} type="primary" danger title={"Ruhezeit löschen"} />
        </Popconfirm>
      </Space>,
    }

  ]



  return (
    <React.StrictMode>
      {contextHolder}
      <Layout>
        <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex',  alignItems: 'center'}}>
          <Space>
            <Button onClick={() => navigate(-1)} icon={<RollbackOutlined/>}>Zurück</Button>
            <Link to={`/_/toern/${toernId}/ruhezeit/neu`}><Button icon={<HourglassOutlined/>} >Ruhezeit hinzufügen</Button></Link>
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <LadeIndikator/>
          ) : (
              <>
            <h2>Törn vom {dayjs(toern.startzeit).format("DD.MM.YYYY HH:mm")}</h2>

            <Table title={tabletitle} columns={columns} dataSource={ruhezeitenliste}></Table>
              </>
          )}
        </Content>
      </Layout>
    </React.StrictMode>
  )



}
export default ToernRuhezeitenListe

