import React, {useEffect, useState} from 'react'
import {Layout, message, Tabs} from 'antd';
import {useNavigate, useParams} from "react-router-dom";

import benutzerService from '../../../services/BenutzerService'
import authenticationService from "../../../services/AuthenticationService";
import BenutzerstammdatenSeite from "./BenutzerstammdatenSeite";

const BenutzerdetailSeite = () => {
  let {id,tab} = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(id !== 'neu');
  const [benutzer, setBenutzer] = useState({});

  useEffect(() => {
    if (id !== 'neu') {
      ladeBenutzer();
//    } else {
//      initBenutzer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = (e, msg) => {
    if (e.response && e.response.data && e.response.data.status === 401) {
      message.error(`Ihre Nutzersitzung ist abgelaufen`);
      authenticationService.doLogout();
      navigate('/_/login');
    } else {
      message.error(msg ? msg :`Beim Laden der Daten ist ein Fehler aufgetreten`);
    }
  }

  const ladeBenutzer = async () => {
    setLoading(true);
    await benutzerService.getBenutzer(id).then(
      res => {
        setLoading(false);
        setBenutzer(res.data)
      }
    ).catch((e) => {
      setLoading(false);
      setBenutzer({})
      console.log("Fehler: ", e);
      if (e.response && e.response.data && e.response.data.status === 404) {
        handleError(e, `Der Benutzer wurde nicht gefunden`);
      } else {
        handleError(`Beim Laden der Benutzerdaten ist ein unbekannter Fehler aufgetreten`);
      }
    });
  };

  var tabs = []
  tabs.push({
    key: "1",
    label: "Stammdaten",
    children: ( <BenutzerstammdatenSeite benutzer={benutzer} setBenutzer={setBenutzer} /> )
  })

  return (
    <Tabs type={'card'} items={tabs} defaultActiveKey={tab==='abschlaege'?'2':'1'}/>
  )
}
export default BenutzerdetailSeite
