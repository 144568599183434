import React from 'react'
import { Outlet } from 'react-router-dom';

function SettingsSeite() {
    return (<>
            <Outlet />
        </>
    )
}

export default SettingsSeite

