import React from 'react';
import './App.css';
import BoludaApp from './components/BoludaApp'

function App() {
  return (
    <div className="App">
      <BoludaApp/>
    </div>
  );
}

export default App;
