import React, {useEffect, useState} from 'react'
import {
  Avatar,
  Card,
  List,
  message,
  Popconfirm
} from 'antd';
import {
  CheckCircleOutlined,
  EditOutlined,
  UserOutlined,
  DeleteOutlined,
  FileAddOutlined,
  SendOutlined, QuestionCircleOutlined, ExclamationCircleOutlined, CheckSquareOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import toernService from "../../services/ToernService";
import dayjs from "dayjs";
import authenticationService from "../../services/AuthenticationService";

const AktiveToerns = () => {
  const [aktiveToerns, setAktiveToerns] = useState([]);
  const [loading, setloading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    ladeAktiveToerns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const ladeAktiveToerns = async () => {
    setloading(true);
    let toerns = []
    setAktiveToerns([])
    toerns.push(neuerEintragKarte());
    await toernService.getAktiveToerns(authenticationService.getLoggedInUserName()).then(
      res => {
        setloading(false);
        res.data.forEach( row => {
          toerns.push(dbToernKarte(row));
        });

        setAktiveToerns(toerns)
      }
    ).catch(function () {
      setloading(false);
      error(`Beim Laden der Törns ist ein Fehler aufgetreten:`);
    });
  };

  function neuerEintragKarte() {
    return {
      title: 'Neuen Törn erfassen',
        icon: <FileAddOutlined />,
      color: '#1677ff',
      bgColor: '#e6f4ff',
      toernTage:[],
      description: <><Link to="/_/toern/neu">Neuen Törn erfassen ...</Link><br /><br/></>,
      actions: [
      <Link to="/_/toern/neu"><FileAddOutlined key="new" title="Neu" label="Neue" /></Link>
      ]
    }
  }

  const dbToernKarte = (db) => {
    var title;
    var icon;
    var color;
    var bgColor;
    var description;

    const ACTIONS = {
//      "NEW": <Link to="/_/toern/new"><FileAddOutlined key="new" title="Neu" label="Neue" /></Link>,
      "EDIT": <Link to={"/_/toern/" + db.id }><EditOutlined key="edit" title="bearbeiten" label="Edit" /></Link>,
      "DELETE": <Popconfirm title="Törn löschen" description="Sind Sie sicher, dass Sie diesen Törn löschen möchten?" onConfirm={() => deleteToern(db.id)} okText="Ja" cancelText="Nein"><DeleteOutlined key="delete" title={'Löschen'}  label="Löschen"/></Popconfirm>,
      "SEND": <SendOutlined  key="send" title={'Einreichen'}  label="Einreichen" onClick={() => einreichen(db.id)}/>,
    }

    var actions;

    switch (db.status) {
      case 'ERFASSUNG' :
        title = 'In Erfassung'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <EditOutlined />
        color = '#fadb14'
        bgColor = '#ffffb8'
        description = <span>{db.startzeit ? dayjs(db.startzeit).format("DD.MM.YYYY hh:mm") : "..." } - {db.endezeit ? dayjs(db.endezeit).format("DD.MM.YYYY hh:mm") : "..." }<br/>{db.schlepper ? db.schlepper : "-"}</span>
        actions = [ACTIONS.DELETE, ACTIONS.EDIT]
        // TODO: EINREICHEN EVTL!
        break;
      case 'EINGEREICHT' :
        title = 'Stunden werden geprüft'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <CheckSquareOutlined />
        color = '#bbbbbb'
        bgColor = '#eeeeee'
        description = <span>{db.startzeit ? dayjs(db.startzeit).format("DD.MM.YYYY hh:mm") : "..." } - {db.endezeit ? dayjs(db.endezeit).format("DD.MM.YYYY hh:mm") : "..." }<br/>{db.schlepper ? db.schlepper : "-"}</span>
        actions = [ ACTIONS.EDIT ];
        break;
      case 'GENEHMIGT' :
        title = 'Genehmigt'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <CheckCircleOutlined />
        description = <span>{db.startzeit ? dayjs(db.startzeit).format("DD.MM.YYYY hh:mm") : "..." } - {db.endezeit ? dayjs(db.endezeit).format("DD.MM.YYYY hh:mm") : "..." }<br/>{db.schlepper ? db.schlepper : "-"}</span>
        color = '#389e0d'
        bgColor = '#d9f7be'
        actions = [ ACTIONS.EDIT ]; //Info Action? PDF / Excel!
        description = <span>{db.startzeit ? dayjs(db.startzeit).format("DD.MM.YYYY hh:mm") : "..." } - {db.endezeit ? dayjs(db.endezeit).format("DD.MM.YYYY hh:mm") : "..." }<br/>{db.schlepper ? db.schlepper : "-"}</span>
        break;
      case 'RÜCKFRAGE' :
        title = 'Rückfrage'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <QuestionCircleOutlined />
        description = <span>{db.startzeit ? dayjs(db.startzeit).format("DD.MM.YYYY hh:mm") : "..." } - {db.endezeit ? dayjs(db.endezeit).format("DD.MM.YYYY hh:mm") : "..." }<br/>{db.schlepper ? db.schlepper : "-"}</span>
        color = '#cf1322'
        bgColor = '#fff1f0'
        actions = [ ACTIONS.EDIT ]; //Info Action?
        description = <span>{db.startzeit ? dayjs(db.startzeit).format("DD.MM.YYYY hh:mm") : "..." } - {db.endezeit ? dayjs(db.endezeit).format("DD.MM.YYYY hh:mm") : "..." }<br/>{db.schlepper ? db.schlepper : "-"}</span>
        break;
      default:
        title = 'Unerwarteter Törnstatus'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <ExclamationCircleOutlined />
        color = '#cf1322'
        bgColor = '#ffccc7'
        actions = []; //Info Action?
        description="Bitte kontaktieren Sie den Administrator"
    }
    return {
      id: db.id,
      startdatum: db.startdatum,
      startzeit: db.startzeit,
      endedatum: db.endedatum,
      endezeit: db.endezeit,
      toernTage: db.toernTage,
      arbeitszeit: db.arbeitszeit,
      ruhezeit: db.ruhezeit,
      meldungen: db.meldungen,
      status: db.status,
      title: title,
      icon: icon,
      color: color,
      bgColor: bgColor,
      description: description,
      actions: actions
    };
  }

  const confirmDelete = async () => {
    console.log("Gelöscht ...");
    messageApi.success('Der Törn wurde gelöscht');
  }

  const deleteToern = async (id) => {

    //TODO: benutzername aus db toern ...
    await toernService.deleteToern(authenticationService.getLoggedInUserName(), id).then(
      _ => {
        ladeAktiveToerns();
        confirmDelete();
      }
    ).catch((e) => {
        error(`Beim Löschen des Törns ist ein Fehler aufgetreten:`);
        console.error(e);
    });
  }

  const einreichen= (id) => {
    console.log("Einreichen " + id)
  }

  return (<>
      {contextHolder}
    {loading ? (
      "Loading"
    ) : (
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        style={{margin:'16px'}}
        dataSource={aktiveToerns}
        renderItem={(item) => (
          <List.Item>
            <Card
              style={{backgroundColor: item.bgColor}}
              actions={item.actions}
            ><Card.Meta
              avatar=
              <Avatar icon={item.icon ? item.icon : <UserOutlined/>}
                              style={{backgroundColor: item.color}}/>
              /*
              {
                <Badge offset={[0,24]} count={<ClockCircleOutlined twoToneColor='#ffcccc' style={{  color: '#ff0000' }} />}>
              <Avatar icon={item.icon ? item.icon : <UserOutlined/>}
                              style={{backgroundColor: item.color}}/>
                </Badge>
              }
              */
              title={item.title}
              description={item.description}

            />{item.content}

              {/*

              <Row>
                <Col style={{ textAlign: "left" }} span={12}>Status:</Col>
                <Col style={{ textAlign: "right" }} span={12}>{item.status}</Col>
              </Row>

              <Row>
                <Col style={{ textAlign: "left" }} span={12}>Arbeitszeit:</Col>
                <Col style={{ textAlign: "right" }} span={12}>{item.arbeitszeit}</Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "left" }} span={12}>Ruhezeit:</Col>
                <Col style={{ textAlign: "right" }} span={12}>{item.ruhezeit}</Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "left" }} span={24}>Meldungen:</Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "right" }} span={24}>
                  {item.meldungen && item.meldungen.map(m => <Alert style={{paddingBottom: "4px", paddingTop: "4px", marginBottom: "2px" }} message={m} type="error" showIcon />)}
                </Col>
              </Row>
              */}
            {/*
            <List size={"small"}
                  dataSource={item.toernTage.map(t => ({label: dayjs(t.datum).format('DD.MM')}))}
                  renderItem={(item) => (
                    <List.Item>
                      <Row style={{width:"100%"}}>
                        <Col span={3}>{item.label}</Col>
                        <Col span={6}>
                          <div>
                            <div style={{width:"50%"}}>50%</div>
                          </div>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
            >

            </List>
            */}
            </Card>
          </List.Item>
        )}
      />
      )}
    </>
  )

}

export default AktiveToerns

