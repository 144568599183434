import React, {useEffect, useState} from 'react'
import {
  Button, Col,
  DatePicker,
  Form,
  InputNumber,
  Layout,
  message, Row,
  Space, Table,
} from 'antd';
import {Link, useOutletContext, useParams} from "react-router-dom";

import benutzerService from '../../services/BenutzerService'
import {ArrowLeftOutlined, SaveOutlined, UndoOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import LadeIndikator from "../LadeIndikator";
import {AbschlagIcon} from "../icons/CustomIcons";

const {Content} = Layout;

const currencyFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

const BordpersonalAbschlaegeDetailseite = () => {
  let {id} = useParams();
  const [jahr, setJahr] = useOutletContext();

  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(id !== 'neu');
  const [benutzer, setBenutzer] = useState({});
  const [abschlaege, setAbschlaege] = useState([]);

  const [form] = Form.useForm();



  const [abschlagliste, setAbschlagliste] = useState([]);

  useEffect(() => {
    syncAbschlaegeForm()
    setAbschlagliste(
      [...Array(12).keys()].map(m => {
        return {
          idx: m,
          monat: dayjs().month(m),
          abschlag: (abschlaege && abschlaege[m]) ? abschlaege[m].abschlag : 0,
          tarif: (abschlaege && abschlaege[m]) ? abschlaege[m].tarifAbschlag : 0,
          manuell: (abschlaege && abschlaege[m]) ? abschlaege[m].manuell : false,
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abschlaege]);


  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    ladeBenutzer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id !== 'neu') {
      getBenutzerabschlaege();
    }
  }, [ jahr, benutzer ]);

  const ladeBenutzer = async () => {
    setLoading(true);
    await benutzerService.getBenutzer(id).then(
      res => {
        setLoading(false);
        setBenutzer(res.data)
      }
    ).catch((e) => {
      setLoading(false);
      setBenutzer({})
      // TODO: Fehler einblenden?
      console.log("Fehler: ", e);
      switch (e.response.status) {
        case 404:
          error(`Der Benutzer wurde nicht gefunden`);
          // TODO: Disable Form
          break;
        default:
          error(`Beim Laden der Benutzerdaten ist ein unbekannterFehler aufgetreten`);
      }
    });
  };

  const getBenutzerabschlaege = async () => {
    setLoading(true);
    await benutzerService.retrieveAbschlaege(id, jahr).then(
      res => {
        setLoading(false);
        setAbschlaege(res.data);
//        form.setFieldValue('abschlaege', res.data)
      }
    ).catch((e) => {
      error(e.response.data.message ? e.response.data.message :  "Es ist ein unbekannter Fehler aufgetreten." );
      console.log("Error ", e);
      setAbschlaege([]);
      setLoading(false);
    });
  };

  const syncAbschlaegeForm = () => {
    if (abschlaege) {
      abschlaege.forEach( (a,i) => {
        form.setFieldValue(['abschlaege', i], a.abschlag);
        form.setFieldValue(["monate", i], dayjs().utc().year(jahr).month(i).date(1).format("YYYY-MM-DD"));
        form.setFieldValue(['tarif', i], a.tarifAbschlag);
      })
    }
  }
  const speichereAbschlaege = (formData) => {
    var postData = formData["monate"].map((m,i) => {
      return {
        "monat": m,
        "abschlag": formData["abschlaege"][i]
      }
    });
    benutzerService.saveAbschlaege(benutzer.id, postData).then(
      () => {
        messageApi.open({
          type: 'info', content: "Die SFN-Zuschläge wurden gespeichert",
        });
      }
    ).catch((e) => {
      error(e.response.data.message ? e.response.data.message :  "Es ist ein unbekannter Fehler aufgetreten." );
      console.log("Error ", e);
    });
  }

  const columns=[
    {
      title: 'Monat',
      dataIndex: 'monat',
      key: 'monat',
      render: (monat) => monat.format("MMMM")
    },
    {
      title: 'SFN-Zuschlag',
      dataIndex: 'abschlag',
      key: 'abschlag',
      render: (abschlag, record) => <>
        <Form.Item name={['monate', record.idx]} hidden={true}></Form.Item>
          <Form.Item name={['abschlaege', record.idx]}>
            <InputNumber
              disabled={record.monat.isBefore(dayjs())}
              formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value?.replace(/€\s?|(,*)/g, '')}
            />
          </Form.Item>
        </>
    },
    {
      title: 'Tarif',
      dataIndex: 'tarif',
      key: 'tarif',
      render: (t) => currencyFormatter.format(t)
    },
    {
      title: 'Manuell',
      dataIndex: 'manuell',
      key: 'manuell',
      render: (id) => id ? "ja" : "nein"
    },
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => <Space>
        <Button disabled={record.monat.isBefore(dayjs())} icon={<UndoOutlined />}
        title={"Auf Tarif zurücksetzen"}
        ></Button>
      </Space>,
    }
  ]



  return (
    <>
      {contextHolder}
      <Layout>
        <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex'}} >
          <Space>
            <Link to={"/_/bordpersonal"}><Button icon={<ArrowLeftOutlined/>} >Zur Übersicht</Button></Link>
            <DatePicker defaultValue={dayjs().utc().year(jahr)} format={'YYYY'} picker="year"
                        onChange={ (ev) => setJahr(ev ? dayjs(ev.$d).year() : dayjs().utc().year() ) } />
            <Button icon={<SaveOutlined  />}
            onClick={() => form.submit()}>
              Speichern
            </Button>
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <LadeIndikator />
          ) : (
              <Form
                form={form}
                onFinish={speichereAbschlaege}
              >
                    <Table
                      title={() => (
                      <Row gutter={[0,0]}>
                        <Col flex="auto"><h2 style={{margin:'0px'}}><AbschlagIcon/> SFN-Zuschläge für {benutzer.vorname} {benutzer.nachname}</h2></Col>
                      </Row>
                      )}
                      pagination={false}
                      columns={columns} dataSource={abschlagliste}></Table>

                <Form.Item>
                  <Button icon={<SaveOutlined  />} htmlType="submit">
                    Speichern
                  </Button>
                </Form.Item>
              </Form>
          )}
        </Content>
      </Layout>
    </>



  )
}
export default BordpersonalAbschlaegeDetailseite
