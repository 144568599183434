import React, {useEffect, useState} from 'react'
import {Button, DatePicker, Divider, Form, Input, InputNumber, Layout, message, Space,} from 'antd';
import {useNavigate, useOutletContext, useParams} from 'react-router-dom';
import tarifService from "../../../services/TarifService";
import authenticationService from "../../../services/AuthenticationService";
import dayjs from "dayjs";
import {CalendarOutlined, RollbackOutlined} from '@ant-design/icons';
import LadeIndikator from "../../LadeIndikator";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const {Content} = Layout;

dayjs.locale('de')
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Berlin');

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const TarifzeitraumBearbeitenSeite = () => {
  const [tarifgruppeId, tarifgruppeName, tarifzeitraeume] = useOutletContext();
  let {id} = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(id !== 'neu');

  const [tarifzeitraum, setTarifzeitraum] = useState({});
  const [form] = Form.useForm();

  const error = (msg) => {
    messageApi.open(    {
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    if (id !== 'neu') {
      loadTarifzeitraum(id);
    } else {
      initTarifzeitraum();
    }
  }, []);

  useEffect(() => {
    if (tarifzeitraum) {
      form.setFieldValue('gueltigAb', dayjs(tarifzeitraum.gueltigAb))
      if (tarifzeitraum.tarife) {
        tarifzeitraum.tarife.forEach(t => {
          form.setFieldValue(['tarife', t.rang, 'abschlag'], t.abschlag)
          form.setFieldValue(['tarife', t.rang, 'grundheuer'], t.grundheuer)
        })
      }
    }
//    form.setFieldValue('id', tarifzeitraum.id)
//    form.setFieldValue('tarifgruppe', tarifzeitraum.tarifgruppe ? tarifzeitraum.tarifgruppe.id : null)
//    form.setFieldValue(['tarife'],  tarifzeitraum.tarife)
  }, [tarifzeitraum]);

  const initTarifzeitraum = () => {
    setTarifzeitraum({
      'gueltigAb': dayjs(),
      'tarifgruppe': {id : tarifgruppeId},
    })
  }

  const loadTarifzeitraum = async () => {
    setLoading(true);

    await tarifService.getTarifzeitraum(id).then(
      res => {
        setLoading(false);
        setTarifzeitraum({
          id: res.data.id,
          gueltigAb: res.data.gueltigAb,
          tarifgruppe: res.data.tarifgruppe,
          tarife: res.data.tarife
        })
      }
    ).catch((e) => {
      setLoading(false);
      error(`Beim Laden des Tarifzeitraums ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  }

  const showInfo = (msg) => {
    messageApi.open({
      type: 'info', content: msg,
    });
  }

  const saveTarifzeitraum = (frm) => {

    let tar = Object.keys(form.getFieldValue('tarife')).map(k => ({
      "rang": k,
      "grundheuer": form.getFieldValue(['tarife',k,'grundheuer']),
      "abschlag": form.getFieldValue(['tarife',k,'abschlag']),
    }))

    const zeitraum = {
      id: tarifzeitraum.id,
      gueltigAb: dayjs.utc(form.getFieldValue('gueltigAb').format('DD.MM.YYYY'), 'DD.MM.YYYY'),
      tarifgruppe: {
        id: tarifgruppeId
      },
      tarife: tar
    };

    const future = (id === 'neu')
      ? tarifService.createTarifzeitraum(authenticationService.getLoggedInUserName(), zeitraum)
      : tarifService.saveTarifzeitraum(authenticationService.getLoggedInUserName(), zeitraum);

    future
      .then(() => {
        showInfo("Der Tarifzeitraum wurde gespeichert");
        navigate(`/_/settings/tarife`, {replace: true});
        setLoading(false);
      })
      .catch((errorx) => {
          if (errorx.response) {
            const errs = errorx.response.data;
            for (const k of Object.keys(errs)) {
              form.setFields([{name: k, errors: [errs[k]]}]);
            }
            error("Bitte prüfen Sie die Eingaben");
          } else {
            error("Es ist ein unbekannter Fehler aufgetreten. Bitte prüfen Sie die Eingaben und versuchen Sie es erneut");
          }
        }
      );
  };

  return (
    <>
      {contextHolder}
      <Layout>
        <Layout.Header style={{backgroundColor: "#cccccc", display: 'flex', alignItems: 'center'}}>
          <Space>
            <Button onClick={() => navigate(-1)} icon={<RollbackOutlined/>}>Zurück</Button>
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <LadeIndikator/>
          ) : (
            <Form
              form={form}
              {...layout}
              style={{
                maxWidth: 800,
              }}
              onFinish={saveTarifzeitraum}
            >

              <h2><CalendarOutlined /> Tarifzeitraum {id === "neu" ? "erfassen" : "bearbeiten"}</h2>

              <Form.Item name={'id'} hidden={true} noStyle><Input type="hidden" /></Form.Item>

              <Form.Item label="Gültig ab"
                      name={"gueltigAb"}
                      rules={[{required: true, message: 'Das Startdatum muss angegeben werden'}]}
                  >
                    <DatePicker

                        format={"DD.MM.YYYY"}
                        style={{width:'calc(100% - 8px)'}}
                    />
              </Form.Item>

              <Divider />

              <Form.Item label="Tarifkonditionen" >
                <span
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                > Abschlag
                </span>
                <span
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                >
                  Grundheuer
                </span>
              </Form.Item>

              <Form.Item label="Kapitän" >
                <Form.Item
                  name={['tarife', 'Kapitaen','abschlag']}
                  rules={[{ required: true }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                >
                  <InputNumber placeholder="SFN-Zuschlag" precision="2" style={{width:'100%'}} />

                </Form.Item>
                <Form.Item
                  name={['tarife', 'Kapitaen','grundheuer']}
                  rules={[{ required: true }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                >
                  <InputNumber placeholder="Grundheuer" precision="2" style={{width:'100%'}} />
                </Form.Item>
              </Form.Item>

              <Form.Item label="Maschinist" >
                <Form.Item
                  name={['tarife', 'Maschinist','abschlag']}
                  rules={[{ required: true }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                >
                  <InputNumber placeholder="SFN-Zuschlag" precision="2" style={{width:'100%'}} />
                </Form.Item>
                <Form.Item
                  name={['tarife', 'Maschinist','grundheuer']}
                  rules={[{ required: true }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                >
                  <InputNumber placeholder="Grundheuer" precision="2" style={{width:'100%'}} />
                </Form.Item>
              </Form.Item>

              <Form.Item label="Steuermann" >
                <Form.Item
                  name={['tarife', 'Steuermann','abschlag']}
                  rules={[{ required: true }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                >
                  <InputNumber placeholder="SFN-Zuschlag" precision="2" style={{width:'100%'}} />
                </Form.Item>
                <Form.Item
                  name={['tarife', 'Steuermann','grundheuer']}
                  rules={[{ required: true }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                >
                  <InputNumber placeholder="Grundheuer" precision="2" style={{width:'100%'}} />
                </Form.Item>
              </Form.Item>

              <Form.Item label="Schiffsmechaniker" >
                <Form.Item
                  name={['tarife', 'Schiffsmechaniker','abschlag']}
                  rules={[{ required: true }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                >
                  <InputNumber placeholder="SFN-Zuschlag" precision="2" style={{width:'100%'}} />
                </Form.Item>
                <Form.Item
                  name={['tarife', 'Schiffsmechaniker','grundheuer']}
                  rules={[{ required: true }]}
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                >
                  <InputNumber placeholder="Grundheuer" precision="2" style={{width:'100%'}} />
                  </Form.Item>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Space>
                  <Button onClick={() => navigate(-1)} icon={<RollbackOutlined/>}>Zurück</Button>

                  <Button htmlType="submit" >
                    Speichern
                  </Button>
                </Space>
              </Form.Item>
            </Form>

          )}
        </Content>
      </Layout>
    </>
  )
}
export default TarifzeitraumBearbeitenSeite

