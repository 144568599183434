import axios from 'axios'
import ServiceConfig from "./ServiceConfig";

const API_URL = ServiceConfig.API_URL;

class BenutzerService {

  getBenutzerliste() {
    return axios.get(`${API_URL}/api/v1/benutzer`);
  }

  getBordpersonalliste(jahr) {
    return axios.get(`${API_URL}/api/v1/bordpersonal?jahr=${jahr}`);
  }

  getBenutzer(benutzerId) {
    return axios.get(`${API_URL}/api/v1/benutzer/` + benutzerId, {
      validateStatus: function (status) {
        return status >= 200 && status < 300;
      }
    });
  }

  createBenutzer(benutzer) {
    return axios.post(`${API_URL}/api/v1/benutzer`, benutzer);
  }

  updateBenutzer(id, benutzer) {
    return axios.patch(`${API_URL}/api/v1/benutzer/${id}`, benutzer);
  }

  deleteBenutzer(benutzerId) {
    return axios.delete(`${API_URL}/api/v1/benutzer/` + benutzerId);
  }

  retrieveAbschlaege(benutzerId, jahr) {
    return axios.get(`${API_URL}/api/v1/benutzer-abschlaege/` + benutzerId + "/" + jahr);
  }

  saveAbschlaege(benutzerId, data) {
    return axios.post(`${API_URL}/api/v1/benutzer-abschlaege/` + benutzerId, data);
  }

}

const benutzerService = new BenutzerService();
export default benutzerService;