class ServiceConfig {
  // static API_URL  = '';
  // static API_URL  = 'http://localhost:8000';
  // static API_URL  = 'http://192.168.178.111:8000';
  //static API_URL  = process.env.API_URL;
  static API_URL = 'https://boluda-time-64b6cb6e7bd2.herokuapp.com';

}

export default ServiceConfig

