import {Spin} from "antd";
import React from "react";

const LadeIndikator = () => {
  // TODO: ladeStatus hier rein, -> nested content
  return (
    <div style={{ padding: '16px' }}>
      <Spin size="large" />
    </div>
  )
}

export default LadeIndikator