import React, {useEffect, useState} from 'react'
import {Button, Col, Layout, Row, message, Tabs} from 'antd';
import {Link, Outlet} from "react-router-dom";
import {EuroCircleOutlined} from "@ant-design/icons";
import tarifService from "../../../services/TarifService";
import Tarifzeitraumtabelle from "./Tarifzeitraumtabelle";
import {CalendarAddIcon} from "../../icons/CustomIcons";

const { Content } = Layout;

const TarifUebersichtSeite = () => {
  const [tarifgruppen, setTarifgruppen] = useState([]);
  const [loading, setloading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const error = (msg) => {
    messageApi.open(    {
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    loadTarifgruppen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    loadTarifgruppen();
  }

  const loadTarifgruppen = async () => {
    setloading(true);
    await tarifService.getTarifgruppen().then(
      res => {
        setloading(false);
        setTarifgruppen(res.data.map(
          (row, idx) => {
            return {
              key: "grp_" + idx,
              label: row.gruppenname,
              children: <Outlet context={[row.id, row.gruppenname, row.tarifzeitraeume, refresh]} />
            }
          }
        ));
      }
    ).catch((e) => {
      error(`Beim Laden der Tarife ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  };
//                 <Tarifzeitraumtabelle zeitraeume={row.tarifzeitraeume} onDelete={deleteTarifzeitraum} />
  const deleteTarifzeitraum = async (id) => {
    await tarifService.deleteTarifzeitraum(id).then(
      () => {
        loadTarifgruppen();
        confirmTarifzeitraumDeleted();
      }
    ).catch((e) => {
      error(`Beim Löschen des Tarifzeitraum ist ein Fehler aufgetreten`);
      console.error(e);
    });
  }

  const confirmTarifzeitraumDeleted = async () => {
    messageApi.success('Der Tarifzeitraum wurde gelöscht');
  }


  return (
    <>
    <Tabs type={'card'} items={tarifgruppen}/>
      {/*
    <Layout>
      <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex'}} >
        <Link to={"/_/settings/tarifzeitraum/neu"}><Button icon={<CalendarAddIcon/>} >Tarifzeitraum anlegen</Button></Link>
      </Layout.Header>
      <Content>
        <Outlet />
        <>
          <Row style={{backgroundColor:"#ffffff"}}>
            <Col>
              <h2 style={{marginLeft:'20px'}}><EuroCircleOutlined /> Tarife verwalten</h2>
            </Col>
          </Row>
          {contextHolder}
              {loading ? (
                "Loading"
              ) : (
                <>
                  <Tabs type={'card'} items={tarifgruppen}/>
                </>
                )}
        </>
      </Content>
    </Layout>
    */}
  </>
  )
}

export default TarifUebersichtSeite