import {AutoComplete, Flex, Input} from "antd";
import React, {useState} from "react";
import './FormHeader.css';
import {FilterFilled} from "@ant-design/icons";

const TableTitle = ({
                   icon = null,
                  filtervalues = [],
                  onFilter = null,
                   children,

                 }) => {

  const [filterwert, setFilterwert] = useState("");

  const onFilterChange = (fw) => {
    setFilterwert( fw !== "" ? fw : null)
    if (onFilter) {
      onFilter(fw)
    }
  }

  return <Flex justify={"space-between"} wrap={"nowrap"}>
    <h2 style={{margin:'0px', flexBasis: '300px', flexShrink: 0}}>{icon} {children}</h2>
    <AutoComplete
      style={{minWidth:"160px", flexBasis: "auto", flexShrink: 1, marginRight:'32px'}}
      options={filtervalues}
      onChange = {onFilterChange}
      value={filterwert}
      filterOption={(inputValue, option) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    >
      <Input placeholder="Filtern" addonBefore={<FilterFilled style={(filterwert)? {color: '#1677ff'}:{color: 'rgba(0, 0, 0, 0.29)'}} />} allowClear={true}/>
    </AutoComplete>
  </Flex>
};

export default TableTitle