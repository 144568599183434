import React from 'react'
import authenticationService from '../../services/AuthenticationService';
import {Alert, Avatar, Button, Card, Checkbox, Divider, Form, Input, Layout, message, Space} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom';

const {Content} = Layout;

const LoginSeite = (props) => {

  // TODO: Errorhandling, Validierung überarbeiten

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fehler, setFehler] = React.useState(null);

  const error = (msg) => {
    messageApi.open(    {
      type: 'error', content: msg,
    });
  }

  const doLogin = (values) => {
    // TODO: Statdessen Fehlermeldung!
    setFehler(null);
    authenticationService.doLogin(values.username, values.password, values.remember).then((response) => {
      if (props.onLogin) {
        props.onLogin(authenticationService.isUserLoggedIn)
      }
      navigate('/');
    }).catch((e) => {
      if (props.onLogin) {
        props.onLogin(authenticationService.isUserLoggedIn)
      }

      if (e.response && e.response.data) {
        setFehler("Es ist ein Fehler aufgetreten: " + e.response.data.message);
     } else if (e.request) {
        setFehler("Der Server antwortet im Moment nicht. Bitte versuchen Sie es in Kürze erneut")
      } else {
        setFehler( "Es ist ein unerwartetes Problem bei der Anmeldung aufgetreten!");
      }
      error( fehler);
    }, (err) => {
      setFehler( "Der Login ist augenblicklich nicht möglich. Bitte Laden Sie die Seite neu!");
      error( fehler);
    })
  }

  return (<>
    {contextHolder}
    <Layout>
        <Content className='login-content'>
          <Card cover={<img alt="VB Rasant" src="/img/header_URAG_vb_rasant.jpeg"/>}>
            <Card.Meta
              avatar={<Avatar style={{backgroundColor: '#152947'}} icon={<UserOutlined/>}/>}
              title="Anmeldung"
              description="Bitte geben Sie Benutzernamen und Passwort ein"
            />
            <Divider/>

            <Space>
              <Form form={form} className='login-form' onFinish={doLogin}>

                {fehler &&
                  <Form.Item><Alert message={fehler} showIcon type="error"/></Form.Item>}

                <Form.Item name="username"
                           rules={[{type: 'string', min: 6}, {type: 'email', warningOnly: true}, {
                             required: true, message: 'Bitte geben Sie Ihren Benutzernamen ein!',
                           },]}

                >
                  <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Benutzername"/>
                </Form.Item>

                <Form.Item name="password"
                           rules={[{
                             required: true, message: 'Bitte geben Sie Ihren Passwort ein!',
                           }

                           ]}
                >
                  <Input.Password autoComplete='true' prefix={<LockOutlined className="site-form-item-icon"/>}
                                  placeholder="Passwort"/>
                </Form.Item>

                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Anmeldedaten merken</Checkbox>
                  </Form.Item>

                  <Link className="login-form-forgot" to="/_/passwort-vergessen">
                    Passwort vergessen
                  </Link>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Anmelden
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Card>
        </Content>
      </Layout>
    </>)
}

export default LoginSeite

