import axios from 'axios'
import ServiceConfig from "../services/ServiceConfig";

const API_URL = ServiceConfig.API_URL;

class TarifService {

    getTarifgruppen() {
        return axios.get(`${API_URL}/api/v1/tarifgruppen/`);
    }

    getTarifTabelle(email, von, bis ) {
        return axios.get(`${API_URL}/api/v1/tarife/meine?von=${von}&bis=${bis}`);
    }

    getTarifzeitraum(tarifId) {
        return axios.get(`${API_URL}/api/v1/tarifzeitraeume/${tarifId}?ts=${new Date().getTime()}`)
    }

    createTarifzeitraum(email, tarifzeitraum) {
        return axios.post(`${API_URL}/api/v1/tarifzeitraeume?email=${email}`, tarifzeitraum )
    }

    saveTarifzeitraum(email, tarifzeitraum) {
        return axios.patch(`${API_URL}/api/v1/tarifzeitraeume/${tarifzeitraum.id}?email=${email}`, tarifzeitraum )
    }

    deleteTarifzeitraum(tarifzeitraumId) {
        return axios.delete(`${API_URL}/api/v1/tarifzeitraeume/` + tarifzeitraumId);
    }


    /*
    getTarifliste() {
        return axios.get(`${API_URL}/api/v1/tarife`);
    }

    getTarif(tarifId) {
        return axios.get(`${API_URL}/api/v1/tarife/${tarifId}`);
    }

    createTarif(tarif) {
        return axios.post(`${API_URL}/api/v1/tarife`, tarif);
    }

    updateTarif(id, tarif) {
        return axios.put(`${API_URL}/api/v1/tarife/${id}`, tarif);
    }

    deleteTarif(tarifId) {
        return axios.delete(`${API_URL}/api/v1/tarife/` + tarifId);
    }

  getTarifgruppen() {
      return axios.get(`${API_URL}/api/v1/tarifgruppe`);
  }

*/
}

const tarifService =  new TarifService();
export default tarifService;