import React, {useEffect, useState} from 'react'
import {
  Button,
  Layout,
  Table,
  message,
  Spin,
  DatePicker,
  Space,
  Popconfirm, Popover
} from 'antd';
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  CheckOutlined, CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
  UserAddOutlined,
  WarningTwoTone
} from "@ant-design/icons";
import _ from "lodash";
import toernService from "../../services/ToernService";
import authenticationService from "../../services/AuthenticationService";
import dayjs from "dayjs";
import TableTitle from "../base/TableTitle";
import {ToernIcon} from "../icons/CustomIcons";
import { Prompt } from "@five-show/antd-prompt";
import MonthSelector from "../base/MonthSelector";

const { Content } = Layout;

const hfnFmt = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const ToernMonatsberichtListe = () => {
  let {jahr, monat} = useParams();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const pMonat = jahr && monat ? dayjs(jahr + "-" + monat + "-01 00:00") : dayjs();

  const [datum, setDatum] = useState(pMonat);
  const [toernliste, setToernliste] = useState([]);
  const [loading, setloading] = useState(true);
  const [feldFilter, setFeldFilter] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    ladeGenehmigteToerns()
  }, [datum]);

  const ladeGenehmigteToerns = async () => {
    setloading(true);
    await toernService.getMonatsBericht(datum).then(
      res => {
        setloading(false);
        setToernliste(
          res.data.map(row => ({
            key: `t_${row.benutzer.id}`,
            benutzer: row.benutzer,
            hafentage: row.hafentage,
            reisekilometer: row.reisekilometer,
            shuttlekilometer: row.shuttlekilometer
          }))
        )
      }
    ).catch((e) => {
      setloading(false);
      error(`Beim Laden der Törns ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    setFeldFilter(filters);
  };

  const changeMonat = (d) => {
    if (!d) {
      d = dayjs();
    }
    setDatum(d)
    navigate("/_/toerns/monat/"  + d.format("YYYY/MM") , { replace: true})
  }
  const tabletitle = () => {
    return <TableTitle icon={<ToernIcon/>}
                  filtervalues={
                    toernliste
                      .reduce(function(acc, b) {
                        acc.push(b.benutzer.vorname);
                        acc.push(b.benutzer.nachname);
                        return acc;
                      }, [])
                    .filter((value, index, array) => array.indexOf(value) === index)
                    .map(s => ({ label: s, value: s}))
                  }
                  onFilter={setGlobalFilter} >Genehmigte Törns {datum.format("YYYY/MM")}</TableTitle>
  }

  const filterData = toernliste => formatter => toernliste.map( item => ({
    text: formatter(item),
    value: formatter(item)
  }));

  const columns=[
    {
      title: 'Benutzer',
      children: [
      {
        title: 'Vorname',
        dataIndex: 'benutzer',
        key: 'benutzerVorname',
        sorter: (a, b) => a.benutzer.vorname.localeCompare(b.benutzer.vorname),
        filters: _.uniqWith(filterData(toernliste)(i => i.benutzer.vorname), _.isEqual),
        filteredValue: feldFilter.vorname || null,
        onFilter: (value, record) => record.benutzer.vorname.includes(value),
        render: (benutzer) => <>{benutzer.vorname}</>
      },
      {
        title: 'Nachname',
        dataIndex: 'benutzer',
        key: 'benutzerNachname',
        sorter: (a, b) => a.benutzer.nachname.localeCompare(b.benutzer.nachname),
        filters: _.uniqWith(filterData(toernliste)(i => i.benutzer.nachname), _.isEqual),
        filteredValue: feldFilter.nachname || null,
        onFilter: (value, record) => record.benutzer.nachname.includes(value),
        render: (benutzer) => <>{benutzer.nachname}</>
      }
    ]
    },
    {
      title: 'Hafentage',
      children: [
        {
          title: 'Kapitän',
          dataIndex: 'hafentage',
          key: 'hafentageKapitaen',
          render: (hafentage) => (hafentage && hafentage.summeKapitaen) ? hfnFmt.format(hafentage.summeKapitaen) : "",
        },
        {
          title: 'Maschinist',
          dataIndex: 'hafentage',
          key: 'hafentageMaschinist',
          render: (hafentage) => (hafentage && hafentage.summeMaschinist) ? hfnFmt.format(hafentage.summeMaschinist) : "",
        },
        {
          title: 'Steuermann',
          dataIndex: 'hafentage',
          key: 'hafentageSteuermann',
          render: (hafentage) => (hafentage && hafentage.summeSteuermann) ? hfnFmt.format(hafentage.summeSteuermann) : "",
        },
        {
          title: 'Schiffsmechaniker',
          dataIndex: 'hafentage',
          key: 'hafentageSchiffsmechaniker',
          render: (hafentage) => (hafentage && hafentage.summeSchiffsmechaniker) ? hfnFmt.format(hafentage.summeSchiffsmechaniker) : "",
        },
        {
          title: 'Summe',
          dataIndex: 'hafentage',
          key: 'hafentage',
          render: (hafentage) => (hafentage && hafentage.total) ? hfnFmt.format(hafentage.total) : "",
        }
      ]
    },
    {
      title: 'Kilometer',
      dataIndex: 'reisekilometer',
      key: 'reisekilometer',
    },
    {
      title: 'Shuttle(km)',
      dataIndex: 'shuttlekilometer',
      key: 'shuttlekilometer',
    },
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      filteredValue: globalFilter ? [globalFilter] : null,

      onFilter: (value, record) =>
        record.benutzer.nachname.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.benutzer.nachname.toString().toLowerCase().includes(value.toLowerCase())
      ,

      render: (id, record) => <Space>
        <Link to={"/_/toern/" + id}><Button icon={<EditOutlined />} /></Link>
      </Space>,
    }
  ]

  return (
    <>
      {contextHolder}
      <Layout>
        <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex',  alignItems: 'center'}}>
          <Space>
            <MonthSelector onChange = { changeMonat } value= { datum } />
            {/*
            <DatePicker defaultValue={dayjs()}
                        format={['MMM YYYY', 'MM.YYYY', 'M.YYYY', 'M.YY', 'MM.YY', 'DD.MM.YYYY', 'D.M.YYYY']}  picker="month"
                        onChange={changeMonat} />const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
            defaultValue={dayjs('01/01/2015', dateFormatList[0])} format={dateFormatList}
            <DatePicker defaultValue={dayjs()} format={'MMM YYYY'}  picker="month"
                      onChange={changeMonat} />
            Hier Userauswahl ?
            <Link to={"/_/toern/neu"}><Button icon={<UserAddOutlined/>} >Neuen Törn erfassen</Button></Link>
            */}
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <div style={{ padding: '16px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table title={tabletitle} size={"small"} columns={columns} dataSource={toernliste} onChange={handleChange}></Table>
          )}
        </Content>
      </Layout>
    </>
  )
}

export default ToernMonatsberichtListe