import React from 'react'
import authenticationService from '../../services/AuthenticationService';
import {Alert, Avatar, Button, Card, Divider, Form, Input, Layout, message, Space} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';

const {Content} = Layout;

const PasswortTokenSeite = (props) => {
  let {token} = useParams();

  const [searchParams] = useSearchParams();
  let email = searchParams.get('email');

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fehler, setFehler] = React.useState(null);

  const error = (msg) => {
    messageApi.open(    {
      type: 'error', content: msg,
    });
  }

  const showInfo = (msg) => {
    messageApi.open({
      type: 'info', content: msg,
    });
  }

  const doResetPassword = (values) => {
    // TODO: Statdessen Fehlermeldung!
    setFehler(null);
    authenticationService.resetPassword(values.email, values.password, values.token).then((response) => {
      navigate('/');
      showInfo("Das Passwort wurde zurückgesetzt")
    }).catch((e) => {
      if (e.response && e.response.data) {
        setFehler("Es ist ein Fehler aufgetreten: " + e.response.data.message);
     } else if (e.request) {
        setFehler("Der Server antwortet im Moment nicht. Bitte versuchen Sie es in Kürze erneut")
      } else {
        setFehler( "Es ist ein unerwartetes Problem bei der Anmeldung aufgetreten!");
      }
      error( fehler);
    }, (err) => {
      setFehler( "Der Login ist augenblicklich nicht möglich. Bitte Laden Sie die Seite neu!");
      error( fehler);
    })
  }

  return (<>
    {contextHolder}
    <Layout>
        <Content className='login-content'>
          <Card cover={<img alt="VB Rasant" src="/img/header_URAG_vb_rasant.jpeg"/>}>
            <Card.Meta
              avatar={<Avatar style={{backgroundColor: '#152947'}} icon={<UserOutlined/>}/>}
              title="Neues Passwort erstellen"
              description="Sie können hier ein neues Passwort erstellen"
            />
            <Divider/>

            <Space>
              <Form form={form} className='login-form' onFinish={doResetPassword}
                    initialValues={{ email: email, token: token }}
              >

                {fehler &&
                  <Form.Item><Alert message={fehler} showIcon type="error"/></Form.Item>}

                <Form.Item name="email"
                           rules={[{type: 'email', warningOnly: true}, {
                             required: true, message: 'Bitte geben Sie Ihre Emailadresse ein!',
                           },]}

                >
                  <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Ihre Emailadresse"/>
                </Form.Item>

                <Form.Item name="token"
                           rules={[{
                             required: true, message: 'Bitte geben Sie den Sicherheitstoken ein!',
                           },]}
                >
                  <Input placeholder="Sicherheitscode"/>

                </Form.Item>
                Sie haben keinen Sicherheitscode erhalten? <Link to={"/_/passwort-vergessen"}>Hier erneut anfordern</Link>
                <Form.Item name="password"
                           rules={[{
                             required: true, message: 'Bitte geben Sie Ihren Passwort ein!',
                           }]}
                >
                  <Input.Password autoComplete='true' prefix={<LockOutlined className="site-form-item-icon"/>}
                                  placeholder="Ihr neues Passwort"/>
                </Form.Item>

                  <Form.Item name="password_repeat"
                             rules={[{
                               required: true, message: 'Bitte wiederholen geben Sie Ihr Passwort!',
                             },
                             (formInstance) => ({
                               message: 'Die Passworte müssen übereinstimmen',
                               validator(rule, value) {
                                 if (value === null) {
                                    return Promise.resolve();
                                 }
                                 const pwdValue = formInstance.getFieldValue('password');
                                 if (value !== pwdValue) {
                                   return Promise.reject(new Error());
                                 }
                                 return Promise.resolve();
                               },
                             }),

                             ]}
                  >
                    <Input.Password autoComplete='true' prefix={<LockOutlined className="site-form-item-icon"/>}
                                    placeholder="Passwort wiederholen"/>
                  </Form.Item>

                  <Form.Item>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Passwort ändern
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Card>
        </Content>
      </Layout>
    </>)
}

export default PasswortTokenSeite

